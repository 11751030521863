<template>
  <nav class="navbar is-dark is-fixed-top">
    <div class="container">
      <div class="navbar-brand">
        <a class="navbar-item" href="/">
          SpaceEngineers Tools
        </a>

        <div class="navbar-burger burger" data-target="topNav">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div id="topNav" class="navbar-menu">
        <div class="navbar-start">
          <!-- <a class="navbar-item" href="/">Components Calculator</a> -->
          <!-- <router-link :to="{ name: 'root_path', params: {} }" class="navbar-item">Home</router-link> -->
        </div>

        <!-- <div class="navbar-end">
          <div class="navbar-item">
            <button v-if="loggedIn()"  @click="logout" type="button">Logout</button>

            <div class="field is-grouped" v-if="!loggedIn()">
              <p class="control">
                <router-link :to="{ name: 'login_path', params: {} }" class="navbar-item button is-small ">Sign In</router-link>
              </p>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </nav>


  <!-- <div class='row'>
    <div class='col-xs-12'>
      <select class="pull-right" v-model="locale" style="margin-top: 10px;">
        <option v-for="locale in availableLocales" :value="locale" :key="locale">
          {{ locale | uppercase }}
        </option>
      </select>

      <h1>{{ $t('title') }}</h1>

      <ul class="nav nav-pills">
        <li :class="activeOn(['root_path'])">
          <router-link :to="{ name: 'root_path' }">{{ $t('nav.homepage') }}</router-link>
        </li>
        <li :class="activeOn(['musicians_path', 'musician_path'])">
          <router-link :to="{ name: 'musicians_path' }">{{ $t('nav.musicians') }}</router-link>
        </li>
        <li :class="activeOn(['errors_path', 'errors_path'])">
          <router-link :to="{ name: 'errors_path' }">{{ $t('nav.errors') }}</router-link>
        </li>
      </ul>
    </div>
  </div> -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {

  // data: function() {
  //   return {
  //     // ...this.$store.state.AuthStore,
  //     locale: window.I18n.locale
  //
  //     // availableLocales: window.I18n.availableLocales,
  //     // loggedIn(): false
  //   }
  // },
  //
  // methods: {
  //   ...mapGetters('AuthStore', [
  //     'loggedIn'
  //   ]),
  //
  //   logout () {
  //     this.$store.dispatch('AuthStore/logout').then(() => {
  //       this.$router.push({name: 'login_path'})
  //     })
  //   }
  //   // ...mapActions('AuthStore', [
  //   //   'logout'
  //   // ])
  //   // activeOn: function(paths) {
  //   //   if(paths.includes(this.$route.name)) {
  //   //     return 'active';
  //   //   } else {
  //   //     return '';
  //   //   }
  //   // }
  // },
  //
  // watch: {
  //   // locale: function(locale) {
  //   //   let redirectTo = `/${locale}${this.$route.path}`;
  //   //   if(locale == this.availableLocales[0]) {
  //   //     redirectTo = `${this.$route.path}`
  //   //   }
  //   //
  //   //   window.location.href = redirectTo;
  //   // }
  // }
}
</script>
