import Axios from 'axios'

export default {
  namespaced: true,

  state: {
    items:      [],
    components: [],
    ingots:     [],
    ores:       []
  },

  getters: {
    itemQuantity: (state) => (item) => {
      const cartItem = state.items.find(function(_item) {
        return _item.TypeId == item.TypeId && _item.SubtypeId == item.SubtypeId
      })

      console.log(cartItem);

      if (!cartItem) {
        // return 0;
      } else {
        return cartItem.quantity
      }
    },

    cartItems: (state, getters, rootState) => {
      return state.items.map(function(cartItem) {
        const item = rootState.ItemStore.all.find(function(_item) {
          return _item.TypeId == cartItem.TypeId && _item.SubtypeId == cartItem.SubtypeId
        })

        return {
          ...item,
          quantity: cartItem.quantity
        }
      })
    },

    cartPCU: (state, getters, rootState) => {
      let total = 0;
      for (var i = 0; i < getters['cartItems'].length; i++) {
        let item = getters['cartItems'][i]
        total += item.PCU*item.quantity
      }

      return total
    },

    cartMass: (state, getters, rootState) => {
      let total = 0;
      let components = getters['cartComponents']
      for (var key in components) {
        let cmp = components[key];
        total += parseInt(cmp.root.Mass) * cmp.total
      }

      return total
    },


    cartVolume: (state, getters, rootState) => {
      let total = 0;
      let components = getters['cartComponents']
      for (var key in components) {
        let cmp = components[key];
        total += parseInt(cmp.root.Volume) * cmp.total
      }

      return total
    },


    cartComponents: (state, getters, rootState) => {
      let components = {}

      state.items.forEach(function(item) {
        let rootItem = rootState.ItemStore.all.find(function(_itm) {
          return _itm.SubtypeId == item.SubtypeId && _itm.TypeId == item.TypeId
        })

        rootItem.components.forEach(function(cmp) {
          if (components[cmp.Subtype] == null) {
            let rootComponent = rootState.ComponentStore.all.find(function(_rootCmp) {
              return cmp.Subtype == _rootCmp.SubtypeId
            })
            let total = parseInt(cmp.Count)*item.quantity;

            components[cmp.Subtype] = {
              total: total,
              root: rootComponent
            }
          } else {
            components[cmp.Subtype].total += parseInt(cmp.Count)*item.quantity
          }
        })
      })

      return components
    },

    cartIngots: (state, getters, rootState) => {
      let ingots = {}

      let components = getters['cartComponents']
      for (var key in components) {

        if (typeof components[key].root.ingots === 'undefined') {
          // component without prerequisites
          continue;
        }

        let compIngots = components[key].root.ingots

        compIngots.forEach((ingot) => {
          if (ingots[ingot.SubtypeId] == null) {
            let rootIngot = rootState.ComponentStore.blueprints.find(function(_ing) {
              return ingot.SubtypeId+"OreToIngot" == _ing.SubtypeId
            })

            ingots[ingot.SubtypeId] = {
              root: rootIngot,
              total: parseFloat(ingot.Amount)*parseInt(components[key].total)
            }
          } else {
            ingots[ingot.SubtypeId].total += parseFloat(ingot.Amount)*parseInt(components[key].total)
          }
        });
      }

      return ingots
    },

    cartOres: (state, getters, rootState) => {
      let ores = {}
      let ingots = getters['cartIngots']

      for (var key in ingots) {
        let ingot = ingots[key];

        if (typeof ingot.root === 'undefined') {
          // component without prerequisites
          continue;
        }

        let results = ingot.root.results
        let oreTotal = Math.max(
          parseInt(ingot.total) / (1 * parseFloat(results.Amount)),
          parseInt(ingot.total)
        );

        if (ores[key] == null) {
          ores[key] = {
            total: parseFloat(oreTotal)
          }
        } else {
          ores[key].total += parseFloat(oreTotal)
        }

      }

      return ores;
    }
  },

  actions: {
    resetCart ({state, rootState, commit}) {
      // rootState.ItemStore.commit('reset')
      // console.log(rootState);
      commit('ItemStore/reset', null, { root: true })
      commit('resetCart')
    },

    addItemToCart ({ state, rootState, commit }, payload) {
      const cartItem = state.items.find(function(_item) {
        return _item.TypeId == payload.item.TypeId && _item.SubtypeId == payload.item.SubtypeId
      })
      if (!cartItem) {
        commit('pushItemToCart', payload)
      } else {
        commit('incrementItem', payload)
      }
    },

    rmItemToCart ({ state, rootState, commit }, payload) {
      const cartItem = state.items.find(function(_item) {
        return _item.TypeId == payload.item.TypeId && _item.SubtypeId == payload.item.SubtypeId
      })
      if (cartItem) {
        commit('decrementItem', payload)
      }
    },

    setItemQuantity ({state, rootState, commit}, payload) {
      commit('setItemQuantity', payload)
    }
  },

  mutations: {
    resetCart (state) {
        state.items = []
        state.components = []
        state.ingots = []
        state.ores = []
    },

    setItemQuantity (state, { item, quantity }) {
      const cartItem = state.items.find(function(_item) {
        return _item.TypeId == item.TypeId && _item.SubtypeId == item.SubtypeId
      })

      if (!cartItem) {
        state.items.push({
          TypeId: item.TypeId, SubtypeId: item.SubtypeId,
          quantity: quantity
        })
      } else {
        cartItem.quantity = quantity
      }
    },

    pushItemToCart (state, {item, quantity}) {
      state.items.push({
        TypeId: item.TypeId, SubtypeId: item.SubtypeId,
        quantity: quantity
      })
    },

    incrementItem (state, {item, quantity}) {
      const cartItem = state.items.find(function(_item) {
        return _item.TypeId == item.TypeId && _item.SubtypeId == item.SubtypeId
      })
      cartItem.quantity = cartItem.quantity + quantity
      // Vue.set(cartItem, 'quantity', cartItem.quantity+quantity)
    },

    decrementItem (state, {item, quantity}) {
      const cartItem = state.items.find(function(_item) {
        return _item.TypeId == item.TypeId && _item.SubtypeId == item.SubtypeId
      })

      cartItem.quantity = cartItem.quantity - quantity
      if (cartItem.quantity < 0) {
        cartItem.quantity = 0
      }


      if (cartItem.quantity == 0) {
        let index = state.items.findIndex(function(_item) {
          return _item.TypeId == item.TypeId && _item.SubtypeId == item.SubtypeId
        })
        state.items.splice(index, 1)
      }
    },
  }
}
