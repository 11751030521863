import Vue from 'vue/dist/vue';

import { library } from '@fortawesome/fontawesome-svg-core'
import {
   faUserSecret,
   faHeart,
   faEye,
   faAngleDown,
   faAngleUp,
   faChevronLeft,
   faSpinner,
   faTrashAlt,
   faFolder
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faUserSecret,
  faHeart,
  faEye,
  faAngleDown,
  faAngleUp,
  faChevronLeft,
  faSpinner,
  faTrashAlt,
  faFolder
)

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('font-awesome-icon', FontAwesomeIcon)
