import Vue from 'vue/dist/vue'
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import store from '../vue/stores'

import Home from '../vue/home.vue'

// Buefy
import Buefy from 'buefy/dist/buefy'
import icons from '../icons'

Vue.use(Buefy, {
  defaultIconComponent: "font-awesome-icon",
  defaultIconPack: "fa"
})

const router = new VueRouter({
  mode: 'history',
  base: window.location.pathname,
  // base: `${I18n.prefix}`,
  routes: [
    {
      path: '/',
      component: Home,
      name: 'root_path',
      // meta: {
      //   requireAuth: true
      // }
    }
  ]
});

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    router,
    store
  }).$mount('#app')
})
