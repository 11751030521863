var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("nav", { staticClass: "navbar is-dark is-fixed-top" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "navbar-brand" }, [
          _c("a", { staticClass: "navbar-item", attrs: { href: "/" } }, [
            _vm._v("\n        SpaceEngineers Tools\n      ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "navbar-burger burger",
              attrs: { "data-target": "topNav" }
            },
            [_c("span"), _vm._v(" "), _c("span"), _vm._v(" "), _c("span")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "navbar-menu", attrs: { id: "topNav" } }, [
          _c("div", { staticClass: "navbar-start" })
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }