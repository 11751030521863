<template lang="html">
  <layout>
    <template v-slot:sidebar>
      <div class="card importBlueprint">
        <div class="card-header">
          <p class="card-header-title">
            Import Blueprint
          </p>
        </div>

        <div class="card-content">
          <b-upload v-model="file" @input="handleBlueprint">
            <a class="button is-light is-small">
              <span>Click to add blueprint (.sbc file)</span>
            </a>
          </b-upload>
          <br />
          <br />
          Path: <code class="is-small is-dark">%APPDATA%\SpaceEngineers\Blueprints\local</code>

        </div>
      </div>
      <!-- End Import Card -->

      <!-- <div class="card importBlueprint">
        <div class="card-header">
          <p class="card-header-title">
            Settings
          </p>
        </div>

        <div class="card-content">
        </div>
      </div> -->
      <!-- End Settings Card -->

      <div class="card">
        <div class="card-header">
          <p class="card-header-title">
            Blocks
          </p>
          <span class="card-header-icon" style="font-size: 14px">
            <b-switch :rounded="false" @input="updatecubeSizeFilter"></b-switch>
            <span v-if="cubeSizeFilter()">Large</span>
            <span v-if="!cubeSizeFilter()">Small</span>
          </span>
        </div>

        <div class="card-content">
          <b-input type="text" class="search"
                  placeholder="Search by name"
                  :value="nameFilter()"
                  @input="updateNameFilter">
          </b-input>

          <div class="content sidebarBlocks">
            <div class="columns  is-mobile  is-multiline">
              <div class="column is-12 item" v-for="item in filteredItems()">
                <div class="columns  is-mobile is-vcentered">
                  <div class="column is-2" style="text-align: center !important;">
                    <img :src="item.Icon" >
                  </div>
                  <div class="column is-10">
                    <div class="columns is-mobile">

                      <div class="column is-8" style="font-size: 14px;">
                        {{ altImageText(item) }}
                      </div>
                      <div class="column is-4">
                        <input style="text-align: center;" class="input is-small" min="0" type="number" v-model="item.addedCount"
                          v-on:change="setItemQuantity2({item: item, quantity: $event.target.value})"
                        >
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div><br />

      <!-- End Blocks Card -->

    </template>

    <div class="">
      <div class="card">
        <div class="card-header">
          <p class="card-header-title total">
            Total&nbsp;&nbsp;
            <span class="tag is-dark">PCUs: {{ cartPCU() }}</span>&nbsp;
            <span class="tag is-dark">Dry Mass: {{ humanizeNumber(cartMass()) }} Kg</span>
          </p>
          <span class="card-header-icon" style="font-size: 14px">
            <button class="button is-small is-dark" @click="resetCart()">Reset</button>
          </span>
        </div>
      </div>

      <div class="card components">
        <div class="card-header">
          <p class="card-header-title">
            Components&nbsp;&nbsp;
            <span class="tag is-dark">Volume: {{ humanizeNumber(cartVolume()) }} L</span>
          </p>
          <span class="card-header-icon" style="font-size: 14px">
          </span>
        </div>
        <div class="card-content">
          <div class="content" style="font-size: 13px;">
            <div class="columns is-mobile is-multiline">
              <div class="column is-2 item" style="text-align: center" v-for="(item, key, index) in cartComponents()">
                <div v-if="typeof item.root !== 'undefined'">
                  <b-tooltip :label="altImageText(item)"
                              position="is-top"
                              type="is-dark">
                  <div style="text-align: center !important;">
                    <img :src="item.root.Icon" :alt="altImageText(item)" width="50" height="50"><br />
                    {{ humanizeNumber(item.total) }}
                  </div>
                </b-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Components Card -->

      <div class="card ingots">
        <div class="card-header">
          <p class="card-header-title">
            Ingots
          </p>
          <span class="card-header-icon" style="font-size: 14px">
          </span>
        </div>

        <div class="card-content">
          <div class="content" style="font-size: 13px;">

            <div class="columns is-mobile is-multiline">
              <div class="column is-2 item" style="text-align: center" v-for="(item, key, index) in cartIngots()">
                <b-tooltip :label="key"
                           position="is-top"
                          type="is-dark">
                  <div style="text-align: center !important;">
                    <strong>{{ key }}</strong><br />
                    {{ humanizeNumber(item.total.toFixed(0)) }}
                  </div>
                </b-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Ingots Card -->

      <div class="card ores">
        <div class="card-header">
          <p class="card-header-title">
            Ores
          </p>
          <span class="card-header-icon" style="font-size: 14px">
          </span>
        </div>

        <div class="card-content">
          <div class="content" style="font-size: 13px;">
            <div class="columns is-mobile is-multiline">
              <div class="column is-2 item" style="text-align: center" v-for="(item, key, index) in cartOres()">
                <div>
                  <b-tooltip :label="key"
                            position="is-top"
                            type="is-dark">
                    <div style="text-align: center !important;">
                      <strong>{{ key }}</strong><br />
                      {{ humanizeNumber(item.total.toFixed(0)) }}
                    </div>
                  </b-tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Ores Card -->
    </div>
  </layout>
</template>

<script>
import Layout from './shared/layout'
import { mapGetters, mapActions, mapState } from 'vuex'

export default {
  methods: {
    change (item, quantity) {
      console.log(item);
      console.log(quantity);
    },

    ...mapGetters('ItemStore', [
      'allItems', 'filteredItems'
    ]),

    ...mapGetters('CartStore', [
      'cartItems', 'cartComponents', 'cartIngots', 'cartOres', 'cartVolume', 'cartPCU', 'cartMass', 'itemQuantity'
    ]),

    ...mapState('ItemStore', [
      'nameFilter', 'cubeSizeFilter'
    ]),

    ...mapActions('CartStore', [
      'addItemToCart', 'rmItemToCart', 'resetCart', 'setItemQuantity'
    ]),

    setItemQuantity2 (payload) {
      this.setItemQuantity(payload)
      this.$buefy.toast.open({
        queue: false,
        message: 'Block(s) added',
        duration: 200,
        // position: 'is-top-right'
      })
    },

    updateNameFilter (payload) {
      this.$store.commit('ItemStore/SET_NAME_FILTER', payload)
    },

    updatecubeSizeFilter (payload) {
      this.$store.commit('ItemStore/SET_BLOCK_SIZE_FILTER', payload)
    },

    altImageText (item) {
      let newName;

      if (typeof item.DisplayName !== 'undefined') {
        newName = item.DisplayName
      }
      else if (typeof item.root.DisplayName !== 'undefined') {
        newName = item.root.DisplayName
      }

       newName = newName
        .replace('DisplayName_Block_', '')
        .replace('DisplayName_', '')
        .replace('Item', '')
        .replace('_', ' ')
        .replace(/([a-z])([A-Z])/g, '$1 $2')
      return newName
    },

    humanizeNumber (n, options) {
      options = options || {};
      var d = options.delimiter || ',';
      var s = options.separator || '.';
      n = n.toString().split('.');
      n[0] = n[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1' + d);
      return n.join(s);
    },

    handleBlueprint (file) {
      const parser = new DOMParser();
      const reader = new FileReader();
      let self = this
      reader.onload = function (e) {
        const doc = parser.parseFromString(e.target.result, "text/xml")

        let blocks = doc.getElementsByTagName("MyObjectBuilder_CubeBlock")

        let names = []

        for (var i = 0; i < blocks.length; i++) {
          let b = blocks[i]
          let subTypeId = b.getElementsByTagName("SubtypeName")[0].textContent
          let typeId = b.getAttribute('xsi:type')

          let foo = {
            type: typeId,
            subtype: subTypeId
          }
          names.push(foo)
        }

        names.forEach(function(blk) {
          let item = self.allItems().find(function(_item) {
            return _item.SubtypeId == blk.subtype
          })

          if (typeof item === 'undefined') {
            let typeId = blk.type.replace('MyObjectBuilder_', '')

            item = self.allItems().find(function(_item) {
              return _item.TypeId == typeId && _item.SubtypeId == null
            })
          }

          if (typeof item === 'undefined') {
            console.log('Undefined item found : ');
            console.log(blk);
          } else {
            self.addItemToCart({item: item, quantity: 1})
            item.addedCount++
          }

        })
      }
      reader.readAsText(file);
    },

  },

  components: {
    Layout
  },
  mounted () {
    this.$store.dispatch('ComponentStore/index')
    this.$store.dispatch('ItemStore/index')
  },
  created () {
    this.$buefy.snackbar.open({
        message: 'Items are automatically extracted from game datas (1.196.018) and may not work properly (broken images).<br />         Special Credits: <a href="https://steamcommunity.com/sharedfiles/filedetails/?id=801185519" target="_blank">Colorful Icons Mod (Steam Workshop)</a><br />Discord: m4t#4223',
        type: 'is-warning',
        position: 'is-bottom-right',
        indefinite: true,
    })
  },
  data () {
    return {
      file: null
    }
  }
}
</script>

<style lang="css">
  .sidebarBlocks .item {
    border-bottom: 1px solid rgb(112, 112, 112);
    margin-right: 10px;
    padding-right: 10px;
  }

  .search {
    margin-top: -10px;
    margin-bottom: 20px;
  }

  .sidebarBlocks {
    height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
    /* scrollbar-width: thin; */
  }

  strong {
    color: #ffffff;
  }

  .card-content h4 {
    border-bottom: 1px solid #fff
  }
  .card.ores .card-header,
  .card.components .card-header,
  .card.ingots .card-header {
    background-color: rgb(45, 45, 45)
  }

  code {
    margin-top: 15px;
    color: rgb(14, 255, 45);
    background-color: #424242;
  }

</style>
