<template>
  <div class="layout">
    <nav-top></nav-top>

    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-5">
            <slot name="sidebar"></slot>
          </div>

          <div class="column is-7">
            <slot></slot>
          </div>

        </div>
      </div>
    </section>

    <img src="https://pw.fullstack.coffee/matomo.php?idsite=2&amp;rec=1" style="border:0" alt="" />

  </div>
</template>


<script>
import NavTop from './nav_top';
export default {
  components: {
    NavTop
  },

  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Set your colors
$primary: #8c67ef;
$primary-invert: findColorInvert($primary);
$twitter: #4099FF;
$twitter-invert: findColorInvert($twitter);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
    "white": ($white, $black),
    "black": ($black, $white),
    "light": ($light, $light-invert),
    "dark": ($dark, $dark-invert),
    "primary": ($primary, $primary-invert),
    "info": ($info, $info-invert),
    "success": ($success, $success-invert),
    "warning": ($warning, $warning-invert),
    "danger": ($danger, $danger-invert),
    "twitter": ($twitter, $twitter-invert)
);


// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $light;

$box-background-color : $black-ter;
$modal-card-body-background-color  : rgb(105, 105, 105);
$modal-card-foot-background-color  : rgb(105, 105, 105);
$modal-card-head-background-color  : rgb(105, 105, 105);

$input-color : $light;
// $input-background-color : $dark;
$input-background-color: rgb(74, 74, 74);
$input-border-color : $grey-darker;
$input-hover-border-color : $grey-dark;
$input-focus-border-color : $grey-dark;
$input-focus-box-shadow-size : 0;

$card-color : $light;
$card-header-background-color : $black-ter;
$card-header-color : $grey-light;
$card-content-background-color : $dark;
$card-footer-background-color : $grey-dark;
$card-footer-border-top : 1px solid $dark;

$label-color : $light;

$body-color: $light;

$footer-background-color: $black-ter;
$footer-color: $light;

$toast-border-radius: 2px;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";

html,body {
  background-color: #424242;

}

.section {
  margin-top: 20px;
}
.content h4 {
  color: $light !important
}

.notices .toast {
  // margin: 0 !important;
  padding: 10px !important;
  font-size: 13px !important;
}
.card {
  border-radius: 0 !important;
}
// .tag {
//   border: 1px dotted $grey-dark;
// }
//
// img {
//   font-weight: bold;
//   vertical-align:middle;
// }
//
// strong {
//   color: #ffffff;
// }
//
// .item {
//   // border-bottom: 1px solid grey;
//   text-align: center;
//   font-size: 13px;
//
//   background-color: $dark;
//   border: 1px solid $dark;
//
//   &:hover {
//     border: 1px dotted $grey-dark;
//     cursor: pointer;
//   }
//
// }

// h4 {
//   color: $light !important
// }
//
// html,body {
//   background-color: #424242;
//
// }
// .addedItem {
//   border: 1px dotted rgb(0, 117, 148);
//   border-radius: 5px;
// }
//
// .filters {
//   top: 10px !important;
//   position: sticky !important;
//
//   .content {
//     font-size: 13px
//   }
// }
//
// a:hover {
//   color: $grey-light !important;
// }

</style>
