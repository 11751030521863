import Vue from 'vue/dist/vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'

Vue.use(Vuex)

const debug = false //process.env.NODE_ENV !== 'production'

import CartStore from './cart_store'
import ItemStore from './item_store'
import ComponentStore from './component_store'

const store = new Vuex.Store({
  modules: {
    CartStore,
    ItemStore,
    ComponentStore
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
});

export default store;
