var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    {
      scopedSlots: _vm._u([
        {
          key: "sidebar",
          fn: function() {
            return [
              _c("div", { staticClass: "card importBlueprint" }, [
                _c("div", { staticClass: "card-header" }, [
                  _c("p", { staticClass: "card-header-title" }, [
                    _vm._v("\n          Import Blueprint\n        ")
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-content" },
                  [
                    _c(
                      "b-upload",
                      {
                        on: { input: _vm.handleBlueprint },
                        model: {
                          value: _vm.file,
                          callback: function($$v) {
                            _vm.file = $$v
                          },
                          expression: "file"
                        }
                      },
                      [
                        _c("a", { staticClass: "button is-light is-small" }, [
                          _c("span", [
                            _vm._v("Click to add blueprint (.sbc file)")
                          ])
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v("\n        Path: "),
                    _c("code", { staticClass: "is-small is-dark" }, [
                      _vm._v("%APPDATA%\\SpaceEngineers\\Blueprints\\local")
                    ])
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-header" }, [
                  _c("p", { staticClass: "card-header-title" }, [
                    _vm._v("\n          Blocks\n        ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "card-header-icon",
                      staticStyle: { "font-size": "14px" }
                    },
                    [
                      _c("b-switch", {
                        attrs: { rounded: false },
                        on: { input: _vm.updatecubeSizeFilter }
                      }),
                      _vm._v(" "),
                      _vm.cubeSizeFilter()
                        ? _c("span", [_vm._v("Large")])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.cubeSizeFilter()
                        ? _c("span", [_vm._v("Small")])
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "card-content" },
                  [
                    _c("b-input", {
                      staticClass: "search",
                      attrs: {
                        type: "text",
                        placeholder: "Search by name",
                        value: _vm.nameFilter()
                      },
                      on: { input: _vm.updateNameFilter }
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "content sidebarBlocks" }, [
                      _c(
                        "div",
                        { staticClass: "columns  is-mobile  is-multiline" },
                        _vm._l(_vm.filteredItems(), function(item) {
                          return _c(
                            "div",
                            { staticClass: "column is-12 item" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "columns  is-mobile is-vcentered"
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "column is-2",
                                      staticStyle: {
                                        "text-align": "center !important"
                                      }
                                    },
                                    [_c("img", { attrs: { src: item.Icon } })]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "column is-10" }, [
                                    _c(
                                      "div",
                                      { staticClass: "columns is-mobile" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "column is-8",
                                            staticStyle: { "font-size": "14px" }
                                          },
                                          [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(_vm.altImageText(item)) +
                                                "\n                    "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "column is-4" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: item.addedCount,
                                                  expression: "item.addedCount"
                                                }
                                              ],
                                              staticClass: "input is-small",
                                              staticStyle: {
                                                "text-align": "center"
                                              },
                                              attrs: {
                                                min: "0",
                                                type: "number"
                                              },
                                              domProps: {
                                                value: item.addedCount
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.setItemQuantity2({
                                                    item: item,
                                                    quantity:
                                                      $event.target.value
                                                  })
                                                },
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    item,
                                                    "addedCount",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ],
                  1
                )
              ]),
              _c("br")
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c("div", {}, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("p", { staticClass: "card-header-title total" }, [
              _vm._v("\n          Total  \n          "),
              _c("span", { staticClass: "tag is-dark" }, [
                _vm._v("PCUs: " + _vm._s(_vm.cartPCU()))
              ]),
              _vm._v(" \n          "),
              _c("span", { staticClass: "tag is-dark" }, [
                _vm._v(
                  "Dry Mass: " +
                    _vm._s(_vm.humanizeNumber(_vm.cartMass())) +
                    " Kg"
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                staticClass: "card-header-icon",
                staticStyle: { "font-size": "14px" }
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "button is-small is-dark",
                    on: {
                      click: function($event) {
                        return _vm.resetCart()
                      }
                    }
                  },
                  [_vm._v("Reset")]
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card components" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("p", { staticClass: "card-header-title" }, [
              _vm._v("\n          Components  \n          "),
              _c("span", { staticClass: "tag is-dark" }, [
                _vm._v(
                  "Volume: " +
                    _vm._s(_vm.humanizeNumber(_vm.cartVolume())) +
                    " L"
                )
              ])
            ]),
            _vm._v(" "),
            _c("span", {
              staticClass: "card-header-icon",
              staticStyle: { "font-size": "14px" }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-content" }, [
            _c(
              "div",
              { staticClass: "content", staticStyle: { "font-size": "13px" } },
              [
                _c(
                  "div",
                  { staticClass: "columns is-mobile is-multiline" },
                  _vm._l(_vm.cartComponents(), function(item, key, index) {
                    return _c(
                      "div",
                      {
                        staticClass: "column is-2 item",
                        staticStyle: { "text-align": "center" }
                      },
                      [
                        typeof item.root !== "undefined"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "b-tooltip",
                                  {
                                    attrs: {
                                      label: _vm.altImageText(item),
                                      position: "is-top",
                                      type: "is-dark"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "text-align": "center !important"
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: item.root.Icon,
                                            alt: _vm.altImageText(item),
                                            width: "50",
                                            height: "50"
                                          }
                                        }),
                                        _c("br"),
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.humanizeNumber(item.total)
                                            ) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  }),
                  0
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card ingots" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("p", { staticClass: "card-header-title" }, [
              _vm._v("\n          Ingots\n        ")
            ]),
            _vm._v(" "),
            _c("span", {
              staticClass: "card-header-icon",
              staticStyle: { "font-size": "14px" }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-content" }, [
            _c(
              "div",
              { staticClass: "content", staticStyle: { "font-size": "13px" } },
              [
                _c(
                  "div",
                  { staticClass: "columns is-mobile is-multiline" },
                  _vm._l(_vm.cartIngots(), function(item, key, index) {
                    return _c(
                      "div",
                      {
                        staticClass: "column is-2 item",
                        staticStyle: { "text-align": "center" }
                      },
                      [
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              label: key,
                              position: "is-top",
                              type: "is-dark"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "text-align": "center !important"
                                }
                              },
                              [
                                _c("strong", [_vm._v(_vm._s(key))]),
                                _c("br"),
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.humanizeNumber(item.total.toFixed(0))
                                    ) +
                                    "\n                "
                                )
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  }),
                  0
                )
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card ores" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("p", { staticClass: "card-header-title" }, [
              _vm._v("\n          Ores\n        ")
            ]),
            _vm._v(" "),
            _c("span", {
              staticClass: "card-header-icon",
              staticStyle: { "font-size": "14px" }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-content" }, [
            _c(
              "div",
              { staticClass: "content", staticStyle: { "font-size": "13px" } },
              [
                _c(
                  "div",
                  { staticClass: "columns is-mobile is-multiline" },
                  _vm._l(_vm.cartOres(), function(item, key, index) {
                    return _c(
                      "div",
                      {
                        staticClass: "column is-2 item",
                        staticStyle: { "text-align": "center" }
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  label: key,
                                  position: "is-top",
                                  type: "is-dark"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "text-align": "center !important"
                                    }
                                  },
                                  [
                                    _c("strong", [_vm._v(_vm._s(key))]),
                                    _c("br"),
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.humanizeNumber(
                                            item.total.toFixed(0)
                                          )
                                        ) +
                                        "\n                  "
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    )
                  }),
                  0
                )
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }