var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout" },
    [
      _c("nav-top"),
      _vm._v(" "),
      _c("section", { staticClass: "section" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "columns" }, [
            _c("div", { staticClass: "column is-5" }, [_vm._t("sidebar")], 2),
            _vm._v(" "),
            _c("div", { staticClass: "column is-7" }, [_vm._t("default")], 2)
          ])
        ])
      ]),
      _vm._v(" "),
      _c("img", {
        staticStyle: { border: "0" },
        attrs: {
          src: "https://pw.fullstack.coffee/matomo.php?idsite=2&rec=1",
          alt: ""
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }