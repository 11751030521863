// import router from '../routes.js'
import Axios from 'axios'

export default {
  namespaced: true,

  state: {
    all: [],
    nameFilter: '',
    cubeSizeFilter: false,
    currentComponents: {},
    prerequisites: {}
  },

  getters: {
    allItems: state => state.all,

    currentComponents (state, getters, rootState, rootGetters)  {
      return state.currentComponents
    },

    filteredItems (state) {
      let byCubeSize = state.all.filter(function(item) {
        let cubeSize = 'small'
        if (state.cubeSizeFilter == true) {
          cubeSize = 'large'
        }

        return item.CubeSize.toLowerCase() == cubeSize
      });

      if (state.nameFilter.length > 0) {
        return byCubeSize.filter(function(item) {
          return item.DisplayName.toLowerCase().includes(state.nameFilter.toLowerCase())
        })
      }
      else {
        return byCubeSize
      }
    }
  },

  actions: {
    index (context) {
      return Axios.get(window.location.pathname + 'items.json')
        .then((response) => {
          context.commit('SET_ALL', response.data.items)
        })
    }
  },

  mutations: {
    reset (state) {
      // console.log('reset?');
      state.all.forEach(function(item) {
        item.addedCount = 0
      })
    },
    SET_NAME_FILTER (state, data) {
      state.nameFilter = data
    },

    SET_BLOCK_SIZE_FILTER (state, data) {
      state.cubeSizeFilter = data
    },

    SET_ALL (state, data) {
      state.all = data
    }
  }
}
