// import router from '../routes.js'
import Axios from 'axios'

export default {
  namespaced: true,

  state: {
    all: [],
    blueprints: []
  },

  getters: {
    allComponents: state => state.all,
  },

  actions: {
    index (context) {
      return Axios.get(window.location.pathname + 'components.json')
        .then((response) => {
          context.commit('SET_ALL', response.data.components)
          context.commit('SET_BLUEPRINTS', response.data.blueprints)

        })
    }
  },

  mutations: {
    SET_ALL (state, data) {
      state.all = data
    },
    SET_BLUEPRINTS (state, data) {
      state.blueprints = data
      // console.log(data);
    },

  }
}
